import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { useSelectedTitleContext } from "./SelectedTitleContext";

// Create a global style to remove underline from all links
const GlobalStyle = createGlobalStyle`

  a {
    text-decoration: none;
    margin-top: 0.4rem;
    color:rgba(10, 20, 47, 1);
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif;
    text-align: left;
  }
  @media only screen and (max-width: 768px) {
    
    a {
      text-decoration: none;
      color:rgba(10, 20, 47, 1);
      font-size: 0.7rem;
      text-align: left;
      
    }
  }
  
`;

const ExperienceFooter = () => {
  const { hostName } = useSelectedTitleContext();
  return (
    <>
      <GlobalStyle />
      <ContactInfo>
        <div className="imgg">
          {/* <img src="/spaceslogo.png" alt="logo" className="imggg" /> */}
          <Logo className="imggg" />
        </div>
        <div className="content">
          <div className="first">
            <p>+91 8473994822</p>
            <p>
              {hostName === "Bluetick-VR"
                ? "vishesh@bluetickvr.com"
                : "hi@convrse.ai"}
            </p>
          </div>
          <div className="sec">
            <Link to="/about">About</Link>
            <Link to="/our-solutions">Our Solution</Link>

            <Link to="/contact-us">Contact</Link>
          </div>
          <div className="third">
            <Link to="/facebook">Facebook</Link>
            <Link to="/twitter">Twitter</Link>
            <Link to="https://in.linkedin.com/company/convrse-spaces">
              Linkedin
            </Link>
            <Link to="https://www.instagram.com/convrse.spaces/?igsh=MXY3NmM4bjB5OTFybw%3D%3D">
              Instagram
            </Link>
          </div>
        </div>
      </ContactInfo>
    </>
  );
};

const ContactInfo = styled.div`
  box-shadow: 0 -8px 8px rgba(0, 0, 0, 0.05); /* Horizontal offset, vertical offset, blur radius, color */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98.5;
  height: 50vh;
  .imgg {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    .imggg {
      width: 40%;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Poppins", sans-serif;
    width: 80%;
    .first {
      display: flex;
      flex-direction: column;
      width: 35%;
      font-family: "Poppins", sans-serif;
      .p {
        width: 60%;
        font-size: 1.2rem;
      }
    }
    .sec {
      display: flex;
      flex-direction: column;
      width: 30%;
      font-family: "Poppins", sans-serif;
    }
    .third {
      display: flex;
      flex-direction: column;
      width: 30%;
      font-family: "Poppins", sans-serif;
    }
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.2);
    flex-direction: row;
    align-items: center;
    width: 140vw;
    height: 20vh;
    overflow: hidden;
    .img {
      width: 20vw;
      img {
        width: 60%;
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 80vw;
      .first {
        display: flex;
        flex-direction: column;
        width: 70%;
        .p {
          width: 60%;
          font-size: 0.7rem;
          margin-bottom: 0;
        }
        p {
          font-size: 0.7rem;
        }
      }
      .sec {
        display: flex;
        flex-direction: column;
        width: 30%;
      }
      .third {
        display: flex;
        flex-direction: column;
        width: 30%;
      }
    }
  }
`;

export default ExperienceFooter;
