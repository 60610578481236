import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

// Importing all your components

import Home from "../Homepage/Home";
import Frontpage from "../component/Frontpage";
import Experience from "../component/oursolutions/Experience";
import About from "../component/about/About";
import TheValue from "../component/valueadded/TheValue";
import Journey from "../component/journey/Journey";
import Resource from "../component/Resource";
import ContactUs from "../component/contact/ContactUs";
import HomePage from "../component/HomePage";
import Runwal from "../component/runwalTours/Runwal";
import RunnwalRoute from "../component/runwalTours/RunnwalRoute";
import Customizer from "../component/Customizer";

// Component to redirect to /convrse-spaces
const RedirectHome = () => {
  const navigate = useNavigate();
  // const { setHostName } = useSelectedTitleContext();

  useEffect(() => {
    navigate("/convrse-spaces");
  }, [navigate]);

  return null;
};

const AppRou = () => (
  <Routes>
    {/* Initial Redirect */}
    {/* Removed <Navigate to="/convrse-spaces" />  */}

    {/* Main Routes */}
    {/* <Route path="/" element={<RedirectHome />} /> */}

    <Route path="/" element={<HomePage />} />
    <Route path="/home" element={<HomePage />} />
    <Route path="/about" element={<About />} />
    <Route path="/the-value-added-suite" element={<TheValue />} />
    <Route path="/the-journey" element={<Journey />} />
    <Route path="/contact-us" element={<ContactUs />} />
    <Route path="/resources" element={<Resource />} />
    <Route path="/our-solutions" element={<Experience />} />
    <Route path="/runwal-tours" element={<RunnwalRoute />} />
    <Route path="/customizer" element={<Customizer />} />
  </Routes>
);

export default AppRou;
